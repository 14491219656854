// Input label and text field
@mixin global-input__label {
    color: $fi-black;
    display: block;
    font-family: $sans-demi;
    font-size: 0.75rem;
    font-weight: 100;
    letter-spacing: 1.2px;
    line-height: 1.125rem;
    padding-bottom: 5px; //TODO: remove this when all inputs will be placed inside labels
    text-transform: uppercase;
}
@mixin global-input__field {
    border: 0;
    border-radius: 0;
    box-sizing: border-box;
    display: block;
    font-family: $sans-regular;
    font-size: 1rem;
    height: 40px;
    letter-spacing: 0.4px;
    line-height: 1.2rem;
    padding: 0 14px;
    position: relative;
    width: 100%;
}

// Numbered list with number in a blue circle
@mixin numbered-list__counter($size) {
    @include small-bold;

    background-color: $link-blue;
    border-radius: $size * 0.5;
    color: $fi-white-color;
    display: inline-block;
    height: $size;
    line-height: 1.8;
    text-align: center;
    width: $size;
}

@mixin radio-inline($inputSize) {
    input {
        clip: rect(1px, 1px, 1px, 1px);
        position: absolute;
        &:checked + label {
            color: $fi-black;
            &:after {
                display: block;
            }
        }
        &[disabled] {
            & + label {
                &:after {
                    background-color: $light-gray;
                }
            }

            &:checked {
                & + label {
                    &:after {
                        background-color: $light-gray;
                    }
                }
            }
        }
    }
    label {
        cursor: pointer;
        display: block;
        padding: 0 0 0 25px;
        position: relative;
        &:before {
            border: 2px solid $light-gray;
            border-radius: $inputSize * 0.5;
            box-sizing: border-box;
            content: '';
            display: block;
            height: $inputSize;
            left: 0;
            margin-right: $inputSize;
            position: absolute;
            top: 0;
            width: $inputSize;
        }

        &:after {
            content: '';
            display: none;
            background: $link-blue;
            border: none;
            border-radius: calc(#{$inputSize} - 6px);
            height: calc(#{$inputSize} - 6px);
            left: 3px;
            margin-right: $inputSize;
            position: absolute;
            top: 3px;
            width: calc(#{$inputSize} - 6px);
        }
    }
}

// Checkbox
@mixin checkbox__input {
    clip: rect(1px, 1px, 1px, 1px);
    position: absolute;
    &:checked + label {
        color: $gray-dark;
        &:before {
            background: $fi-green-color url('../assets/img/icon-checkbox-square.svg')
                no-repeat center center;
            border: none;
            color: $fi-white-color;
        }
    }
    &[disabled] {
        & + label {
            &:before {
                background-color: $light-gray;
            }
        }

        &:checked {
            & + label {
                &:before {
                    background-color: $light-gray;
                }
            }
        }
    }
}
@mixin checkbox__label($size: 20px) {
    color: $fi-black;
    cursor: pointer;
    display: inline-block;
    font-family: $sans-regular;
    font-size: 1rem;
    font-weight: 300;
    line-height: 1rem;
    min-height: $size;
    padding: 0 0 0 ($size + 5);
    position: relative;
    text-transform: capitalize;
    vertical-align: middle;
    &:before {
        border: 2px solid $light-gray;
        box-sizing: border-box;
        content: '';
        display: inline-block;
        height: $size;
        left: 0;
        margin-right: 10px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: $size;
    }
}

// Account, Settings, and Wizard forms

@mixin form-main {
    &.form-steps {
        margin-top: 0;

        @include break(large) {
            margin-top: 55px;
        }
    }
}

@mixin filter-fields {
    margin: 10px 0 20px 0;

    &:last-child {
        margin-bottom: 30px;
        @include break(medium) {
            margin-bottom: 20px;
        }
    }
}

@mixin required-field-label-mark {
    &::after {
        content: '*';
        margin-left: toRem(4);
        color: $fi-error-color;
    }
}
