.landing-road-ahead {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;

    @include break(medium) {
        align-items: center;
    }

    $base: &;

    &__title {
        width: 100%;
        max-width: toRem(380);
        padding: toRem(50) toRem(20) toRem(32);
        margin: 0 auto;

        @include fontMedium();
        font-size: toRem(26);
        text-align: left;
        line-height: 1;
        color: $fi-dark-gray-color;

        @include break(medium) {
            text-align: center;
        }
    }

    &__sub-title {
        width: 100%;
        max-width: toRem(380);
        padding: 0 toRem(22);
        margin: 0 auto;

        @include fontMedium();
        font-size: toRem(16);
        text-align: left;
        line-height: toRem(24);
        color: $fi-gray-color;

        @include break(medium) {
            max-width: toRem(480);
            text-align: center;
        }

        @include break(large) {
            max-width: toRem(630);
        }
    }

    &__video-container {
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;
        position: relative;
        width: 100%;
        height: toRem(319);

        margin: toRem(32) 0;

        overflow: hidden;

        @include backgroundImg(
            '../assets/images/penske-fleet-video-background.png'
        );

        @include break(medium) {
            height: auto;
            max-height: toRem(594);
            margin: toRem(50) 0 toRem(60);

            background: none;
        }

        &:before {
            content: '';
            position: absolute;
            background-color: $fi-overlay-blue-color;
            opacity: 0.5;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }

        video {
            display: none;
            width: 100%;

            cursor: pointer;

            @include break(medium) {
                display: block;
            }
        }
    }

    &__video-play-button {
        position: absolute;
        width: toRem(110);
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        cursor: pointer;

        @include break(large) {
            width: toRem(160);
        }

        svg {
            width: 100%;
        }
    }

    &__video-popup {
        display: block;
        position: relative;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1000;
        transform: scale(0);

        background: rgba($fi-modal-overlay-color, 0.8);

        &--show {
            transform: scale(1);
            position: fixed;

            iframe {
                display: block;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }

            .video-js {
                display: block;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }

    &__video-popup-close-button {
        display: block;
        position: absolute;
        top: toRem(20);
        right: toRem(20);

        cursor: pointer;
    }
}
