// todo: think of better names, map style guide colors to it
// MAIN COLORS
$fi-dark-blue-color: #002496;
$fi-bright-blue-color: #0073cf;
$fi-bright-blue-color-hover: #338fd9;
$fi-pale-blue-color: #8abde6;
$fi-glacier-blue-color: #79a1c1;
$fi-anakiwa-blue-color: #96d0fc;
$fi-alice-blue-color: #eaf6ff;
$fi-light-sky-blue-color: #d2ecff;
$fi-light-blue-color: #f2f9ff;
$fi-penske-yellow-color: #fecb00;
$fi-penske-yellow-dark-color: #fdca00;
$fi-penske-yellow-light-color: #fffce6;
$fi-orange-color: #f5a623;
$fi-amour-color: #fdf8f9;
$fi-light-pink-color: #fff2f3;
$fi-dark-pink-color: #f4dfe1;
$fi-sam-blue-color: #5fb3f5;
$fi-sam-yellow-color: #ffd41c;

$fi-black: #000000;
$fi-dark-gray-color: #222222;
$fi-chinese-black-color: #171718;
$fi-gray-color: #646469;
$fi-light-gray-color: #969696;
$fi-dove-gray-color: #696969;
$fi-alto-gray-color: #d8d8d8;
$fi-pale-gray: #e9edef;
$fi-middle-gray-color: #747476;
$fi-cape-cod-gray-color: #464747;
$fi-athens-gray-color: #e8ecef;
$fi-oslo-gray-color: #959899;
$fi-polar-color: #f7fafd;
$fi-alabaster-color: #f8f8f8;
$fi-text-description-color: #5c5d5e;

$fi-green-color: #028982;
$fi-green1-color: #068a15;
$fi-white-color: #ffffff;
$fi-ghost-white-color: #f5f9fc;
$fi-white-ten-color: rgba(255, 255, 255, 0.1);
$fi-white-25-color: rgba(255, 255, 255, 0.25);
$fi-white-st-color: rgba(255, 255, 255, 0.85);

// EXTENDED COLORS
$fi-ext1-color: #f5f5f5;
$fi-ext2-color: #edf0f2;
$fi-ext3-color: #e4e9ed;
$fi-ext4-color: #dae8f2;
$fi-ext5-color: #ecf0f2;
$fi-ext6-color: #f6fbff;
$fi-ext7-color: #f5f8fa;

// GLOBAL COLORS
$fi-success-color: #7cae54;
$fi-error-color: #d0021b;
$fi-error-hover-color: #ea3349;
$fi-locked-color: #b3b6b8;
$fi-warning-color: #f59006;

// BORDER COLORS
$fi-border-subtle-color: $fi-ext2-color;
$fi-border-module-color: #d7dde1;
$fi-border-button-color: #bdc5ca;
$fi-border-disabled-button-color: rgba(189, 197, 202, 0.5);
$fi-border-hover-color: #9ea6ac;
$fi-animated-border-hover-color: #d4d7d9;
$fi-border-active-color: #002496;
$fi-border-error-color: #d39aa1;
$fi-border-tab-color: #0067b9;
$fi-border-penske-yellow-color: #feca31;

// OTHER
$fi-shadow-color: rgba(0, 0, 0, 0.1);
$fi-backdrop-color: rgba(0, 0, 0, 0.5);
$fi-hr-color: #e1e7e9;
$fi-inline-textbox-border-color: #cbcbcb;
$fi-lock-icon-color: $fi-border-button-color;
$fi-input-icon-color: $fi-border-button-color;
$fi-inactive-icon-color: #cbcbcb;
$fi-overlay-blue-color: #001146;
$fi-reset-button-color: #fff2f3;
$fi-settings-gray-color: #717274;
$fi-settings-dark-gray-color: #2f2f30;
$fi-settings-yellow-color: #ffcb31;
$fi-info-block-background-color: #eff8ff;
$control-order-border-color: #bcc4c9;
$banner-error-border-color: #e05259;
$banner-error-background-color: #fff0f1;
$banner-info-border-color: #48a9ef;
$button-yellow-background-color: #fff9cb;

// BACKGROUND COLORS
$fi-selectable-background-hover-color: #f5f5f5;
$fi-background-gray-color: #f1f5f8;
$fi-background-light-gray-color: #edf1f4;
$fi-card-background-gray-color: #f0f3f6;
$fi-background-dark-gray-color: #f4f8fb;
$fi-modal-overlay-color: #000b2f;
$fi-background-light-warning-color: #fffaf4;
$fi-background-yellow-warning-color: #fff5e0;
$fi-text-match-yellow: #ffed7c;
$fi-warning-background-color: #fff9f2;
$fi-hover-background-color: #001b71;
$fi-import-step-background: #0075d2;

// OLD DESIGN
$light-black: #252b30;
$link-blue: #3455db;
$link-blue-hover: #144191;
$light-gray: #dde3eb;
$gray-light-med: #738594;
$gray-dark: #404a52;
$gray-light-light: #c5ced3;
$mint: #c3eafd;

$red: #ed1c24;
$white: #fff;
$black: #000;

//ICON COLORS
$fi-icon-color: #c4cacd;

//PRINT COLORS
$print-border-color: #d0d0d0;
$print-color: #555555;

// TOAST COLORS
$fi-toast-success-background: #f2fff3;
$fi-toast-error-background: #ffeaed;
