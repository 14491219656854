@import '@pnsk/ui-common/styles/colors';
@import '@pnsk/ui-common/styles/globals';

.site-footer {
    $first-section-width: 370px;
    $second-section-padding: 40px;
    $footer-bg-light: #6a6a6a;
    $footer-bg-dark: #5a5a5a;
    $footer-link-hover: #e5e5e5;

    margin-top: 40px;
    padding: 30px 0 0;
    background-color: $footer-bg-light;

    &__container {
        @include main-container;
        display: flex;
        flex-flow: row wrap;
    }

    &__nav {
        margin-bottom: 5px;

        @include break(medium) {
            margin-bottom: 0;
        }
    }

    &__link {
        @include small-demi;
        color: $white;
        padding: 0 5px;
        margin: 0;
        text-transform: none;
        letter-spacing: 0;
        cursor: pointer;

        &:first-child {
            padding-left: 0;
        }

        &:last-child {
            border-right: none;
        }

        &:hover {
            color: $footer-link-hover;
            text-decoration: underline;
        }

        svg {
            width: 12px;
            margin-left: 4px;
            margin-top: -4px;
        }

        @include break(medium) {
            border-right: 1px solid $white;
            padding: 0 6px 0 3px;
        }

        &--contact-us {
            display: block;
            font-family: $sans-bold;
            font-size: 1.2rem;
            text-transform: uppercase;
            padding-left: 0;
            margin: 10px 0;
            border: none;
        }

        &--for-customers {
            border-right: none;
            font-size: 0.85rem;
            display: inline-block;
            font-family: 'Avenir LT W01_45 Book1475508', Helvetica, Arial,
                sans-serif;
        }
    }

    &__contact-info {
        width: $first-section-width;
        margin-bottom: 20px;
    }

    &__247-info {
        @include break(medium) {
            padding: 0 $second-section-padding;
            width: 310px;
            border-left: 1px solid $white;
        }

        &--header {
            @include small-bold;
            width: 250px;
            display: flex;
            font-size: 19px;
            text-transform: uppercase;
            align-items: center;
            margin-bottom: 10px;
            line-height: 18px;

            .service-hours {
                font-size: 2.6rem;
                margin-right: 10px;
            }
        }

        &--text {
            color: $white;
            font-size: 0.85rem;
            line-height: 1rem;
            margin: 0;
        }
    }

    &__247-info,
    &__for-customers {
        color: $white;
        margin-bottom: 15px;

        &--subheader,
        &--header {
            @include small-bold;
            text-transform: uppercase;
            font-size: 1.25rem;
            margin-bottom: 10px;
        }
    }

    &__for-customers {
        width: 100%;

        @include break(large) {
            width: auto;
            border-left: 1px solid $white;
            padding-left: $second-section-padding;
        }
    }

    &__copyright {
        @include small-bold;
        text-transform: none;
        letter-spacing: normal;
        line-height: 1.46rem;
        color: $white;
        margin: 0;
        order: 1;

        @include break(medium) {
            width: $first-section-width;
            order: 0;
        }
    }

    &__bottom {
        background-color: $footer-bg-dark;
        padding: 10px 0;

        .site-footer__container {
            align-items: baseline;
        }

        nav {
            @include break(medium) {
                padding-left: $second-section-padding;
            }
        }
    }

    .app-logo {
        color: $white;
        font-size: 1.6rem;
        line-height: 36px;

        &:hover {
            color: $white;
        }
    }
}

.site-footer-print {
    display: none;
}

@media print {
    .site-footer {
        display: none;
    }

    .site-footer-print {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: toRem(62);
        border-top: toRem(1) solid $print-border-color;
        margin-top: toRem(35);
        padding: 0 toRem(16);
    }
}
