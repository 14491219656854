.landing-contact {
    display: none;
    flex-direction: column;
    text-align: center;
    font-size: toRem(14);
    padding: toRem(32) toRem(16);
    border-top: toRem(1) solid $fi-white-25-color;
    border-bottom: toRem(1) solid $fi-white-25-color;

    @include break(medium) {
        display: flex;
    }

    @include break(large) {
        font-size: toRem(16);
    }

    &__link {
        font-size: toRem(14);
        text-transform: capitalize;
        margin: toRem(24) 0;
    }

    &__phone {
        font-size: toRem(14);
    }

    &__text {
        color: $fi-white-color;
        font-size: toRem(14);
    }

    &__email {
        display: block;
        text-decoration: underline;
        margin-bottom: toRem(8);

        &:hover,
        &:link {
            color: $fi-white-color;
        }
    }
}
