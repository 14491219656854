$landing-page-top-btn-background: #5fb3f5;
$landing-page-top-btn-border: #88d0fe;

$landing-page-top-btn-background-active: #08199a;
$landing-page-top-btn-border-active: #1631c7;

$landing-divider-color: #fecb00;
$landing-background-color: #ffffff;

body.device-ios {
    .landing-page__top-btn {
        bottom: toRem(55);
    }
}

.landing-page {
    position: relative;
    height: 100%;

    transition: transform $landing-modal-animation-timings;
    will-change: transform;

    &--push-left {
        transform: translateX(-100vw);
    }

    @include break(medium) {
        will-change: auto;
    }

    &__aside {
        @include break(medium) {
            position: fixed;
            top: 0;
            bottom: 0;
            left: 0;

            width: $landing-aside-width-tablet;
        }

        @include break(large) {
            z-index: 3;
            width: $landing-aside-width-desktop;
        }
    }

    &__container {
        background-color: $landing-background-color;
        @include break(medium) {
            flex-grow: 1;
            padding-left: $landing-aside-width-tablet;
        }

        @include break(large) {
            padding-left: $landing-aside-width-desktop;
        }
    }

    &__divider-line {
        background: linear-gradient(
            90deg,
            $landing-divider-color,
            $fi-white-color
        );
        height: toRem(3);

        &--reverse {
            transform: scaleX(-1);
        }

        @include break(large) {
            width: 75%;

            &--reverse {
                margin-left: 25%;
            }
        }
    }

    &__top-btn {
        opacity: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: fixed;
        right: toRem(18);
        bottom: toRem(18);
        width: toRem(48);
        height: toRem(48);
        background: $landing-page-top-btn-background;
        border: toRem(3) solid $fi-white-color;
        z-index: 2;
        border-radius: 50%;
        color: $fi-white-color;
        text-transform: uppercase;
        font-size: toRem(10);
        @include fontHeavy();
        padding-top: toRem(15);
        transition: opacity 500ms;
        cursor: pointer;

        svg {
            height: toRem(32);
            width: toRem(32);
            fill: $fi-white-color;
            position: absolute;
            top: toRem(-2);
        }

        &:hover,
        &:active,
        &:focus {
            background: $landing-page-top-btn-background-active;
            border-color: $landing-page-top-btn-border-active;
        }

        @include break(medium) {
            display: none;
        }
    }

    &__footer {
        margin-top: 0;
    }
}
