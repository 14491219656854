.landing-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: toRem(32) 0 toRem(16);

    &__logo {
        width: toRem(100);
        height: toRem(36);
        fill: $fi-white-color;
    }

    &__title {
        margin-top: toRem(16);
        margin-bottom: 0;
        @include fontBook();
        font-size: toRem(24);
        line-height: 1;

        &--accent {
            @include fontHeavy();
        }
    }
}
