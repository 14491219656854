@import '../colors';

//prior to use this mixins make sure that font-faces.scss file is included somewhere

// Mixins
$sans-demi: 'Avenir LT W01_85 Heavy1475544', Helvetica, Arial, sans-serif;
$sans-regular: 'Avenir LT W01_45 Book1475508', Helvetica, Arial, sans-serif;
$sans-bold: 'Avenir LT W01_95 Black1475556', Helvetica, Arial, sans-serif;
$sans-medium: 'Avenir LT W01_65 Medium', Helvetica, Arial, sans-serif;
$sans-bebas: 'Bebas Neue Book', Helvetica, Arial, sans-serif;
$serif-regular: 'Swift W01 Regular', Georgia, serif;
$serif-italic: 'Swift W01 Italic', Georgia, serif;
$serif-bold: 'Swift W01 Bold', Georgia, serif;

// Common Font Styles

@mixin small-demi {
    font-family: $sans-demi;
    font-size: 0.75rem;
    font-weight: 100;
    letter-spacing: 1.2px;
    line-height: 1rem;
    text-transform: uppercase;
}

@mixin mid-demi-font {
    font-family: $sans-demi;
    font-size: 0.875rem;
}

@mixin small-bold {
    font-family: $sans-bold;
    font-size: 0.75rem;
    font-weight: 100;
    letter-spacing: 1.2px;
    line-height: 1rem;
    text-transform: uppercase;
}

@mixin placeholder {
    &::placeholder {
        text-overflow: ellipsis;
        width: 100%;
        @content;
    }
}
