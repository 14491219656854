$landing-billboard-height-mobile: toRem(302);
$landing-billboard-height-tablet: toRem(236);
$landing-billboard-height-desktop: toRem(500);
$landing-billboard-height-desktop-xl: toRem(600);

@mixin backgroundImg($url) {
    background: url($url) center no-repeat;
    background-size: cover;
    background-origin: content-box;
}

@mixin pseudoEl() {
    content: '';
    display: block;
    position: absolute;
    height: 100%;
    top: 0;
}

.landing-billboard {
    width: 100%;
    height: $landing-billboard-height-mobile;

    @include backgroundImg('../assets/images/penske-billboard.jpeg');

    @include break(medium) {
        height: $landing-billboard-height-tablet;
        position: relative;

        &::after {
            @include pseudoEl();
            width: 60%;
            right: 0;
            background: url('../assets/images/chevron.svg') left no-repeat;
            background-size: cover;
            background-origin: content-box;
            opacity: 1;
        }
    }

    @include break(large) {
        height: $landing-billboard-height-desktop;
        &::after {
            width: 70%;
        }
    }

    @include break(xlarge) {
        height: $landing-billboard-height-desktop-xl;
        &::after {
            width: 60%;
        }
    }
}
