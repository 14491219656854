@import '@pnsk/ui-common/styles/colors';
@import '@pnsk/ui-common/styles/globals';

app-logo {
    display: block;
}

.app-logo {
    $color: $fi-white-st-color;
    color: $color;
    font-family: $sans-bold;
    font-size: toRem(22);
    letter-spacing: normal;
    line-height: 1.91;
    text-transform: uppercase;

    max-height: toRem(42);
    display: block;
    overflow: hidden;

    span {
        font-family: $sans-regular;
    }

    @include hover {
        color: $color;
    }

    @media print {
        color: $fi-dark-gray-color;
    }
}
