$landing-graphic-padding: toRem(104);
$landing-graphic-border: toRem(1) solid $fi-ext3-color;
$landing-graphic-width: toRem(268);
$landing-graphic-height: toRem(197);

.landing-graphics {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    width: 100%;
    margin-bottom: toRem(10);

    @include break(large) {
        flex-direction: row;
        align-items: flex-start;
    }

    &__item {
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: center;
        height: auto;
        padding: toRem(40) 0;
        position: relative;

        // sass-lint:Pseudo-class force-pseudo-nesting
        &:not(:last-child)::after {
            content: '';
            position: absolute;
            top: 100%;
            right: 0;
            height: toRem(1);
            width: $landing-graphic-width;
            left: 50%;
            transform: translateX(-50%);
            border-top: $landing-graphic-border;
        }

        @include break(large) {
            padding: $landing-graphic-padding toRem(12);

            &:first-of-type {
                padding-left: toRem(16);
            }

            &:last-of-type {
                padding-right: toRem(16);
            }

            // sass-lint:Pseudo-class force-pseudo-nesting
            &:not(:last-child)::after {
                content: '';
                position: absolute;
                top: $landing-graphic-padding;
                bottom: $landing-graphic-padding;
                right: 0;
                left: 100%;
                height: auto;
                width: 0;
                border-right: $landing-graphic-border;
            }
        }
    }

    &__title {
        @include fontMedium();
        text-align: center;
        color: $fi-dark-gray-color;
        font-size: toRem(22);
        line-height: toRem(30);
        width: 100%;

        display: flex;
        align-items: flex-end;
        justify-content: center;
        height: toRem(60);
    }

    &__description {
        @include fontMedium();
        text-align: center;
        color: $fi-gray-color;
        font-size: toRem(16);
        margin: 0;
        width: 100%;
    }

    &__graphic {
        width: 100%;
        max-width: $landing-graphic-width;
        height: auto;

        @include break(large) {
            margin: toRem(24) 0;
            max-width: toRem(300);

            @media screen and (-ms-high-contrast: active),
                screen and (-ms-high-contrast: none) {
                svg {
                    // sass-lint:disable-block no-important
                    // for IE11, need fixed width/height
                    width: toRem(235) !important;
                    height: toRem(235) !important;
                }
            }
        }
    }
}
