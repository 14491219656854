.landing-input-mask {
    position: absolute;
    top: 0;
    bottom: 0;

    display: flex;
    align-items: center;

    white-space: nowrap;
    padding: toRem(1) toRem(13);
    height: toRem(40);
    max-width: toRem(305);
    overflow: hidden;
    @include fontBook();
    font-size: toRem(16);

    &__filled {
        opacity: 0; // filled value should just hold space for placeholder

        color: $fi-dark-gray-color;
        white-space: pre;
    }
    &__placeholder {
        color: $fi-light-gray-color;
        white-space: pre;
    }
}
