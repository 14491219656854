$landing-modal-btn-desktop-width: toRem(139);

@mixin close-modal-desktop {
    width: toRem(20);
    height: toRem(20);
    fill: $fi-light-gray-color;
}

@mixin header-modal-desktop {
    display: flex;
    padding: toRem(25) toRem(20);
    background-color: $fi-ext2-color;
    justify-content: space-between;
}

.landing-overlay {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 8;
    visibility: hidden;
    background: rgba($fi-modal-overlay-color, 0.8);
    opacity: 0;

    &--open {
        visibility: hidden;
    }

    @include break(medium) {
        &--open {
            visibility: visible;
            opacity: 0.85;
            transition: opacity ease 300ms;
            will-change: opacity;
        }
    }
}

.landing-modal {
    $base: '.landing-modal';

    width: 100%;
    height: 100%;
    visibility: hidden;
    z-index: 9;
    display: block;
    position: fixed;
    top: 0;

    @include break(medium) {
        width: auto;
        height: auto;
        border-radius: toRem(3);
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    &__container {
        width: 100%;
        min-height: 100%;
        background-color: $fi-white-color;
        opacity: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        transform: translateX(100%);
        transition: all $landing-modal-animation-timings;

        @include break(medium) {
            width: toRem(500);
            min-height: toRem(309);
            transform: translateY(20%);
            opacity: 0;
        }
    }

    &--open {
        visibility: visible;

        #{$base}__container {
            transform: translateX(0);
            opacity: 1;
        }
    }

    &__header {
        background: $fi-dark-blue-color;
        display: flex;
        padding: toRem(20) toRem(11);
        justify-content: center;
        align-items: center;
        position: relative;

        #{$base}__header-close-btn {
            display: none;
        }

        #{$base}__header-close-btn-mobile {
            display: block;
            position: absolute;
            top: toRem(11);
            left: toRem(10);
            font-size: toRem(36);

            svg {
                width: toRem(14);
                height: toRem(37);
                fill: $fi-white-color;
            }
        }

        @include break(medium) {
            @include header-modal-desktop;

            #{$base}__header-close-btn {
                display: block;

                svg {
                    @include close-modal-desktop;
                }
            }

            #{$base}__header-close-btn-mobile {
                display: none;
            }
        }
    }

    &__header-title {
        color: $fi-white-color;
        text-align: center;
        @include fontMedium();
        font-size: toRem(18);

        @include break(medium) {
            text-align: left;
            color: $fi-dark-gray-color;
        }
    }

    &__content {
        padding: toRem(25) toRem(14) toRem(25) toRem(25);
    }

    &__content-input {
        width: toRem(305);
        margin-top: toRem(20);
        position: relative;

        input:focus::placeholder {
            color: transparent;
        }
    }

    &__content-description {
        @include fontBook();
        text-align: left;
        font-size: toRem(14);
        color: $fi-gray-color;
        margin: 0;

        &--bold {
            @include fontHeavy();
        }
    }

    &__button {
        height: toRem(40);
        padding: 0 toRem(20);
        border-radius: $landing-button-border-radius;

        background-color: $fi-dark-blue-color;
        color: $fi-white-color;

        @include fontHeavy();
        font-size: toRem(14);

        transition: $background-color-transition;

        &:hover,
        &:focus {
            background-color: $fi-bright-blue-color;
        }

        &--default {
            background-color: $fi-white-color;
            color: #717274;
            border: toRem(1) solid #d4d7d9;

            &:hover,
            &:focus {
                color: $fi-dark-gray-color;
                background-color: $fi-white-color;
            }
        }
    }

    &__footer {
        padding: toRem(20);
        border-top: toRem(1) solid $fi-ext2-color;
        display: flex;
        text-align: right;
        justify-content: space-between;

        @include break(medium) {
            justify-content: flex-end;

            #{$base}__button {
                width: $landing-modal-btn-desktop-width;
            }
        }

        #{$base}__button {
            width: toRem(163);

            &:last-child {
                margin-left: toRem(16);
            }
        }
    }

    &__search-value {
        @include fontHeavy();
        text-align: left;
        font-size: toRem(16);
        color: $fi-dark-gray-color;
    }

    &__form {
        flex-grow: 1;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        #{$base}__footer--confirm,
        #{$base}__content--confirm {
            display: none;
        }
    }

    .landing-form__field--error {
        background: transparent;
    }

    &--confirm {
        #{$base}__form {
            #{$base}__content,
            #{$base}__footer {
                display: none;
            }

            #{$base}__content--confirm {
                display: block;
            }

            #{$base}__footer--confirm {
                display: block;
                position: relative;

                #{$base}__button {
                    width: 100%;

                    &:last-child {
                        margin-left: 0;
                    }

                    @include break(medium) {
                        width: $landing-modal-btn-desktop-width;
                    }
                }
            }
        }

        #{$base}__header {
            #{$base}__header-close-btn {
                display: block;
                position: absolute;
                top: toRem(16);
                left: toRem(10);
                font-size: toRem(24);

                svg {
                    width: toRem(15);
                    height: toRem(24);
                    fill: $fi-white-color;
                }
            }

            #{$base}__header-close-btn-mobile {
                display: none;
            }

            @include break(medium) {
                @include header-modal-desktop;

                #{$base}__header-close-btn {
                    display: block;
                    position: relative;
                    top: 0;
                    left: 0;

                    svg {
                        @include close-modal-desktop;
                    }
                }

                #{$base}__header-close-btn-mobile {
                    display: none;
                }
            }
        }
    }
}
