.logout-page {
    min-height: 500px;
    height: 100%;
    position: relative;
    background-color: $fi-ext2-color;

    @media (min-width: 30rem) {
        padding-top: 0;
    }

    &__container {
        overflow: hidden;
        width: 100%;
        font-family: 'Avenir LT W01_45 Book1475508', Helvetica, Arial,
            sans-serif;
    }

    &__title {
        line-height: 0;
        margin-top: 50px;
        padding-bottom: 30px;
        position: relative;
        text-align: center;

        &:after {
            background-color: #000000;
            bottom: 0;
            content: '';
            display: block;
            height: 2px;
            left: 50%;
            position: absolute;
            transform: translateX(-50%);
            width: 20px;
        }
    }

    &__intro {
        color: #000000;
        margin: 0 auto;
        text-align: center;
        font-family: $sans-regular;
        font-size: 2.4rem;
        line-height: 2.7rem;
        letter-spacing: 1px;
        color: $gray-dark;

        @include break(medium) {
            max-width: 900px;
        }
    }

    &__main {
        margin: 0;
        max-width: 1440px;
        padding: 0 8px;
        text-align: center;

        @include break(medium) {
            margin: 0 auto;
            padding: 0 40px;
        }
    }

    main {
        position: absolute;
        bottom: 50%;
        margin-bottom: 50px;
    }

    footer {
        position: absolute;
        bottom: 0;
        width: 100%;
    }
}
