@import '../grid';
@import '../colors';
@import '../globals';

$mobile-side-margins: 2.85%;
$side-margins: 8.33%;
$negative-side-margins: 10.012%;

@mixin side-padding($size) {
    padding-left: $size;
    padding-right: $size;
}

@mixin side-margin($size) {
    margin-left: $size;
    margin-right: $size;
}

@mixin main-container {
    @include side-padding(toRem(8));

    @include break(medium) {
        @include side-margin(auto);
        @include side-padding(toRem(40));
    }

    max-width: toRem(1680);
}

// should be used without @mixin flex-grid-col-margin
@mixin flex-grid($cols, $gutter: 0) {
    display: flex;
    flex-wrap: wrap;
    gap: $gutter;

    > * {
        // Reset to prevent external styles break the grid.
        $item-width: calc(
            (100% / #{$cols}) - #{$gutter} + (#{$gutter} / #{$cols})
        );
        width: $item-width;
        box-sizing: border-box;
    }
}

// todo: investigate if it's still needed
.panel {
    background: $fi-white-color;
    border: toRem(1) solid $fi-border-module-color;
    border-radius: $common-border-radius;
    display: flex;
    align-items: center;
    padding: toRem(14);
    margin-bottom: toRem(20);

    &__content {
        flex-grow: 1;
    }
}

@mixin page-overlay-base {
    background-color: $fi-modal-overlay-color;
    z-index: 20;
    right: 0;
    top: 0;
    opacity: 0;
    transition: opacity ease-out 0.2s;
}

@mixin head-line($position: 'bottom') {
    border-#{$position}: toRem(1) solid $mint;
}

@mixin section-head-line {
    @include head-line;
    margin: 0 0 toRem(40);
}

@mixin section-head-text {
    color: $fi-black;
    line-height: 1.5rem;
    font-family: $serif-bold;
    padding: toRem(22) 0 toRem(23);
    text-transform: capitalize;
}

@mixin section-head {
    @include section-head-line;
    @include section-head-text;
}

@mixin responsive-paddings($mobile-indent: $mobile-indent) {
    @include side-padding($mobile-indent);

    @include break(medium) {
        @include side-padding($tablet-indent);
    }

    @include break(large) {
        @include side-padding($laptop-indent);
    }
}

@mixin responsive-negative-margins() {
    @include side-margin(0);

    @include break(medium) {
        @include side-margin(-$tablet-indent);
    }

    @include break(large) {
        @include side-margin(-$laptop-indent);
    }
}

@mixin event-form-grid() {
    @include break(xmedium) {
        display: grid;
        grid-template-columns: repeat(3, minmax(toRem(240), toRem(305)));
        column-gap: toRem(16);
    }
}
