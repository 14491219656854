$landing-webinar-height-mobile: toRem(230);
$landing-webinar-width-mobile: toRem(160);
$landing-webinar-width-desktop: toRem(350);

@mixin btnColor($color, $border-color, $background-color) {
    color: $color;
    border-color: $border-color;
    background: $background-color;
}

.landing-webinar {
    @include fontMedium();
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: toRem(24);
    position: relative;
    margin: 0 auto;
    text-align: center;
    color: $fi-white-color;
    font-size: toRem(16);

    &__title {
        font-size: toRem(20);
        color: $fi-dark-gray-color;
        font-family: $fi-font-medium;
    }

    &__description {
        margin: toRem(24) 0;
        color: $fi-gray-color;
        width: 100%;
    }

    &__buttons-wrapper {
        display: flex;
        grid-gap: toRem(16);
    }

    &__btn {
        width: $landing-webinar-width-mobile;
        height: toRem(48);
        line-height: toRem(48);
        border-radius: toRem(3);
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;

        @include btnColor(
            $fi-white-color,
            $fi-dark-blue-color,
            $fi-dark-blue-color
        );

        &:hover {
            @include btnColor(
                $fi-white-color,
                $fi-bright-blue-color,
                $fi-bright-blue-color
            );
        }
    }

    &__btn-icon-wrapper {
        display: flex;
        align-items: center;
        margin-right: toRem(8);
    }

    &__btn-icon {
        fill: $fi-white-color;
    }

    @include break(medium) {
        color: $fi-white-color;
    }

    @include break(xlarge) {
        align-items: flex-start;
        position: absolute;
        top: $landing-billboard-height-desktop * 0.46;
        right: toRem(108);
        transform: translateY(-20%);
        padding: 0;
        width: $landing-webinar-width-desktop;
        text-align: left;

        &__title {
            font-size: toRem(22);
            color: $fi-white-color;
        }

        &__description {
            color: $fi-white-color;
        }

        &__btn-icon {
            fill: $fi-gray-color;
        }

        &__btn {
            width: toRem(148);
            height: toRem(40);
            border: toRem(1) solid;
            border-radius: toRem(3);
            font-size: toRem(14);
            line-height: toRem(40);
            text-align: center;

            @include btnColor(
                $fi-gray-color,
                $fi-border-button-color,
                $fi-white-color
            );

            &:hover {
                @include btnColor(
                    $fi-dark-gray-color,
                    $fi-border-hover-color,
                    $fi-white-color
                );
            }
        }
    }
}
