.landing-download {
    display: none;
    padding: toRem(32) toRem(16);

    @include break(medium) {
        display: block;
    }

    &__list {
        display: flex;
        justify-content: space-between;
        gap: toRem(16);
        margin-top: toRem(16);
        padding: 0 toRem(8);
    }

    &__text {
        color: $fi-white-color;
        font-size: toRem(14);
        text-align: center;
    }

    &__link {
        display: block;
        height: toRem(40);

        &--appstore {
            background: url('../assets/images/apple-badge.svg') left no-repeat;
            width: toRem(120);
        }

        &--googleplay {
            background: url('../assets/images/google-badge.svg') left no-repeat;
            width: toRem(135);
        }
    }
}
