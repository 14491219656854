@import './fonts';

// TODO: create more mixins with typography variations to have ability
// using it inside component styles
@mixin heading-primary() {
    font-size: toRem(26);
    line-height: toRem(30);
}

@mixin heading-secondary() {
    font-size: toRem(22);
    line-height: toRem(26);
}

@mixin heading-secondary-medium() {
    font-size: toRem(22);
    line-height: toRem(26);
    @include fontMedium();
}

@mixin heading-quaternary() {
    font-size: toRem(16);
    line-height: toRem(20);
}

@mixin heading-tertiary-heavy() {
    font-size: toRem(18);
    line-height: toRem(22);
    @include fontHeavy;
}

@mixin heading-tertiary-lighter() {
    font-size: toRem(18);
    line-height: toRem(22);
}

@mixin text-regular {
    font-size: toRem(16);
    line-height: toRem(20);
}

@mixin text-small() {
    font-size: toRem(14);
    line-height: toRem(18);
}

@mixin text-x-small() {
    font-size: toRem(12);
    line-height: toRem(14);
}
