@use "sass:math";

@function toRem($target) {
    @return math.div($target, 16) * 1rem;
}

$common-border-radius: toRem(3);
$page-block-border-radius: toRem(4);

// @todo: keep only unique
$container-box-shadow: toRem(1) toRem(1) toRem(2) 0 rgba(0, 0, 0, 0.1);
$header-box-shadow: toRem(2) toRem(2) toRem(4) 0 rgba(0, 0, 0, 0.1);
$box-shadow: toRem(2) toRem(2) toRem(4) 0 rgba(0, 0, 0, 0.1);
$box-shadow--modal: 0 toRem(2) toRem(4) 0 rgba(0, 0, 0, 0.5);
$picker-box-shadow: toRem(2) toRem(2) toRem(4) 0 rgba(0, 0, 0, 0.1);

$container-max-width: toRem(1440);

// layers
$page-z-index: 1;
$table-shadow-z-index: 1;
$loader-z-index: 1;
$grid-scroll-z-index: 1;
$grid-sticky-header-z-index: 10;
$grid-panel-z-index: 3;
$mobile-sticky-button-z-index: 9;
$page-sticky-header-z-index: 10;
$top-bar-z-index: 10;
$fullScreen-z-index: 11;
$tooltip-z-index: 12;
$content-above-sticky-header-z-index: 15;
$fi-flash-message-index: 16;
$right-notification-z-index: 17;
$tour-activator-z-index: 19;
$click-outside-overlay-z-index: 20;
$header-with-left-menu-z-index: 20;
$click-outside-aware-z-index: 21;
$left-menu-z-index: 23;
$overlay-z-index: 23;
$modal-z-index: 24;
$header-with-overlay-z-index: 24;
$page-sticky-header-full-screen-z-index: 24;
$tour-activator-desktop-z-index: 24;
$secondary-modal-z-index: 25;
$side-panel-overlay-z-index: 25;
$all-page-overlay-z-index: 25;
$side-panel-z-index: 26;
$main-cover-modal-z-index: 26;
$popups-z-index: 30;
$fi-dropdown-index: $popups-z-index;
$fi-dropdown-arrow-index: $fi-dropdown-index + 1;
$luf-bottom-navigation-bar-z-index: $page-z-index + 1;
$luf-location-details-z-index: $luf-bottom-navigation-bar-z-index + 1;
$guided-tour-active-element-z-index: 101;
$backdrop-z-index: 100;
$cdk-z-index: 1000;

// menu selectors
$left-menu: '.left-menu-open';
$right-bar: '.right-notification';
$support-mode: '.support-mode';
$alert-banner: '.alert-banner';
$native-app-panel: '.native-app-panel';
$native-app-panel--large: '.native-app-panel--large';
$left-closed: 'body#{$right-bar}:not(#{$left-menu})';
$right-closed: 'body#{$left-menu}:not(#{$right-bar})';
$both-open: 'body#{$left-menu}#{$right-bar}';
$both-closed: 'body:not(#{$left-menu}):not(#{$right-bar})';
$support-header-with-alert-banner: 'body#{$support-mode}#{$alert-banner}';
$support-header-with-native-app-panel: 'body#{$support-mode}#{$native-app-panel}';
$support-header-with-native-app-panel--large: 'body#{$support-mode}#{$native-app-panel--large}';
$alert-banner-with-native-app-panel: 'body#{$alert-banner}#{$native-app-panel}';
$alert-banner-with-native-app-panel--large: 'body#{$alert-banner}#{$native-app-panel--large}';
$support-header-with-both-alerts-and-native-panel: 'body#{$support-mode}#{$alert-banner}#{$native-app-panel}';
$support-header-with-both-alerts-and-native-panel--large: 'body#{$support-mode}#{$alert-banner}#{$native-app-panel--large}';
$full-screen-page-mobile: '.full-screen-page-mobile';
$full-page-form: '.full-page-form';
$notifications-panel-content: '.notifications-panel__content';
$support-mode-search: '.support-mode-search';
$responsive-margin-page: '.responsive-margin-page';
$full-width-with-left-menu: '.full-width-with-left-menu';
$locations-page: '.locations-page';
$page-without-left-menu: '.page-without-left-menu';

// variation for layout
$mobile-indent: toRem(24);
$tablet-indent: toRem(32);
$laptop-indent: toRem(40);
$desktop-indent-open-left-menu: toRem(40);
$desktop-indent-open-notification: toRem(40);

$left-menu-width: toRem(72);
$left-menu-opened-width: toRem(288);

$top-menu-height: toRem(72);
$top-menu-height-mobile: toRem(60);

$support-mode-height: toRem(56);

$alert-banner-height: toRem(56);

$native-app-panel-height: toRem(48);
$native-app-panel-height--large: toRem(56);


$right-notification-panel-opened: toRem(288);
$right-notifications-bar-width: toRem(288);
$notifications-bar-header-height: toRem(48);
$notifications-bar-footer-height: toRem(80);

$alert-banner-height-with-native-app-panel: calc(#{$alert-banner-height} + #{$native-app-panel-height});

$alert-banner-height-with-native-app-panel--large: calc(#{$alert-banner-height} + #{$native-app-panel-height--large});

$support-mode-height-with-alerts: calc(#{$support-mode-height} + #{$alert-banner-height});

$support-mode-height-with-native-app-panel: calc(#{$support-mode-height} + #{$native-app-panel-height});

$support-mode-height-with-native-app-panel--large: calc(#{$support-mode-height} + #{$native-app-panel-height--large});

$support-mode-height-with-both-alerts-and-native-app-panel: calc(#{$support-mode-height} + #{$alert-banner-height} + #{$native-app-panel-height});

$support-mode-height-with-both-alerts-and-native-app-panel--large: calc(#{$support-mode-height} + #{$alert-banner-height} + #{$native-app-panel-height--large});

$notifications-panel-height: calc(100vh - #{$notifications-bar-header-height} - #{$top-menu-height} - #{$notifications-bar-footer-height});
$notifications-panel-height-with-alert-banner: calc(#{$notifications-panel-height} - #{$alert-banner-height});

$draggable-item-height: toRem(54);

// animation
$short-transition-duration: 0.3s;
$normal-transition-duration: 0.5s;
$rotate-animation: RotateClockwise 2s infinite linear;

$background-color-transition: background-color $short-transition-duration linear;
