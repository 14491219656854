$landing-requeat-access-background: #f2f9ff;

.landing-request-access {
    @include fontMedium();
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    background: $landing-requeat-access-background;
    text-align: center;
    height: toRem(230);

    &__content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        color: $fi-dark-gray-color;
        margin: 0;
        width: toRem(335);
        font-size: toRem(22);
        line-height: toRem(30);
    }

    @include break(large) {
        padding-top: toRem(40);
        padding-bottom: toRem(37);
        height: auto;

        &__content {
            width: 100%;
        }
    }

    @include break(xlarge) {
        &__content {
            flex-direction: row;
        }
    }

    &__button {
        display: inline;
        padding: 0 0 0 toRem(5);

        line-height: normal;
        color: $fi-dark-blue-color;

        @include fontHeavy();
    }
}
