.landing-form {
    $base: '.landing-form';

    $input-height: toRem(40);
    $input-horizontal-padding: toRem(12);
    $input-z-index: 1;

    $checkbox-height: toRem(19);

    &__server-error {
        display: none;
        padding: toRem(16) toRem(20);

        background-color: $landing-color-error-background;

        &--visible {
            display: block;
        }

        @include break(medium) {
            text-align: center;
        }
    }

    &__server-error-message {
        font-size: toRem(14);
        color: $fi-error-color;
    }

    &__content {
        padding: toRem(16) toRem(24);

        @include break(medium) {
            padding: toRem(24) toRem(16) toRem(32) toRem(16);
        }
    }

    &__row {
        display: flex;
        flex-direction: column;

        & + & {
            margin-top: toRem(32);
        }

        &:last-child {
            margin-top: toRem(24);
        }
    }

    &__remember {
        position: relative;
        display: flex;
        margin: toRem(16) 0 toRem(32);
        height: $checkbox-height;
    }

    &__remember-tooltip {
        position: absolute;
        z-index: $input-z-index + 1;
        bottom: $checkbox-height;
        left: 0;

        margin-bottom: toRem(15);
        padding: toRem(10);

        border-radius: $common-border-radius;
        background-color: $fi-dark-gray-color;

        color: $fi-white-st-color;
        font-size: toRem(14);
        text-align: center;

        opacity: 0;
        visibility: hidden;

        transform: translateY(toRem(20));

        transition: all 250ms ease-out;

        &::after {
            position: absolute;
            bottom: toRem(-4);
            left: toRem(5);

            width: toRem(9);
            height: toRem(9);

            background-color: $fi-dark-gray-color;

            transform: rotate(45deg);
            content: '';
        }

        @include break(large) {
            .landing-checkbox:hover + & {
                opacity: 1;
                visibility: visible;
                transform: translateY(0);
            }
        }
    }

    &__label {
        display: inline-flex;
        margin-bottom: toRem(5);
        align-self: flex-start;

        font-size: toRem(14);
    }

    &__field {
        position: relative;
        flex-grow: 1;

        border: toRem(1) solid $fi-border-button-color;
        border-radius: $common-border-radius;
        background-color: $fi-white-color;
        color: $fi-dark-gray-color;

        &--error {
            border-color: $fi-error-color;
            background-color: $landing-color-error-background;
        }
    }

    &__input {
        display: block;
        position: relative;
        width: 100%;
        z-index: $input-z-index;

        height: $input-height;

        border: none;
        background-color: transparent;

        @include fontBook();
        font-size: toRem(16);

        color: $fi-dark-gray-color;

        &::-ms-reveal {
            display: none;
        }
        &:invalid {
            outline: none;
        }

        &--username {
            padding: 0 $input-horizontal-padding;
        }

        &--password {
            padding: 0 toRem(48) 0 toRem(12);
        }
    }

    &__error-text {
        display: none;
        color: $fi-error-color;
    }

    &__field--error + #{$base}__error-text {
        display: block;
        margin-top: toRem(10);

        @include break(medium) {
            a {
                color: $fi-error-color;
                pointer-events: none;
            }
        }
    }

    &__error-message {
        display: none;

        position: absolute;
        z-index: $input-z-index - 1;
        top: 0;
        right: 0;
        left: 0;

        align-items: center;
        height: $input-height;
        padding: 0 $input-horizontal-padding;

        color: $fi-error-color;

        #{$base}__field--error & {
            display: flex;
        }
    }

    &__password-toggle {
        display: inline-flex;

        position: absolute;
        z-index: $input-z-index;
        top: toRem(12);
        right: toRem(16);

        padding: 0;

        cursor: pointer;

        #{$base}__field--error & {
            display: none;
        }
    }

    &__eye {
        width: toRem(16);
        height: toRem(16);

        fill: $fi-light-gray-color;

        &--active {
            display: none;
        }

        #{$base}__field--password-visible & {
            fill: $fi-dark-blue-color;

            &--inactive {
                display: none;
            }

            &--active {
                display: block;
            }
        }
    }

    &__forgot {
        @include break(medium) {
            text-align: center;
            font-size: toRem(14);
        }
    }

    &__button {
        height: toRem(48);
        padding: 0 toRem(20);
        border-radius: $landing-button-border-radius;

        background-color: $fi-bright-blue-color;

        @include fontHeavy();
        font-size: toRem(14);

        transition: $background-color-transition;

        &:hover,
        &:focus {
            background-color: $fi-bright-blue-color-hover;
        }

        @include break(large) {
            height: toRem(40);
        }
    }

    &__request {
        margin-top: toRem(25);

        @include break(medium) {
            margin-top: toRem(20);

            font-size: toRem(14);
            text-align: center;
        }

        @include break(large) {
            margin-top: toRem(30);
            font-size: toRem(16);
        }
    }
}
