$fi-font-book: 'Avenir LT W01_45 Book1475508', Helvetica, Arial, sans-serif;
$fi-font-medium: 'Avenir LT W01_65 Medium', Helvetica, Arial, sans-serif;
$fi-font-heavy: 'Avenir LT W01_85 Heavy1475544', Helvetica, Arial, sans-serif;
$fi-font-black: 'Avenir LT W01_95 Black1475556', Helvetica, Arial, sans-serif;
$fi-font-roman: 'Avenir LT W01_55 Roman', Helvetica, Arial, sans-serif;

@mixin fontBook() {
    font-family: $fi-font-book;
}

@mixin fontMedium() {
    font-family: $fi-font-medium;
}

@mixin fontHeavy() {
    font-family: $fi-font-heavy;
}

@mixin fontBlack() {
    font-family: $fi-font-black;
}

@mixin fontRoman() {
    font-family: $fi-font-roman;
}

@mixin textMediumDefault() {
    font-family: $fi-font-medium;
    font-size: toRem(16);
    line-height: toRem(20);
}

@mixin textMediumLarger() {
    font-family: $fi-font-medium;
    font-size: toRem(18);
    line-height: toRem(26);
}

@mixin textHeavyDefault() {
    font-family: $fi-font-heavy;
    font-size: toRem(18);
    line-height: toRem(22);
}
