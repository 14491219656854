@use "sass:math";

body {
    background-color: #f1f5f8;
}

.wizard__header {
    background-color: $fi-black;
    z-index: 10;
    top: 0;
    padding: 20px 0;
    position: fixed;
    width: 100%;
}

.wizard__title {
    @include main-container();
    font-family: $serif-bold;
    font-size: 1.25rem;
    line-height: 1.5rem;
    color: $white;
}

// this file is short version of form-elements from main app

.required-dot {
    display: inline-block;
    line-height: 1;
    margin-left: 4px;
    overflow: hidden;
    position: relative;
    text-indent: -999px;
    vertical-align: middle;
    white-space: nowrap;
    width: 8px;

    &:before {
        content: '•';
        color: $red;
        font-size: 1rem;
        height: 8px;
        position: absolute;
        right: 0;
        text-indent: 0;
        width: 8px;
    }
}

.form-steps {
    &__wrapper {
        width: 100%;

        @include break(large) {
            margin: 0 auto;
            max-width: 66.3%;
        }
    }
    &__container {
        background-color: $white;
        max-width: 1360px;
        padding: 43px 0 0;
    }
    &__page-title {
        @include section-head;
        margin-right: $mobile-side-margins;
        margin-left: $mobile-side-margins;
        text-transform: capitalize;

        @include break(small) {
            margin-right: $side-margins;
            margin-left: $side-margins;
        }
    }

    &__header-note {
        @include break(medium) {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
        }

        display: block;
        font-family: $sans-demi;

        font-size: 0.75rem;
        letter-spacing: 1.2px;
        text-transform: uppercase;
    }

    .required-dot {
        color: $red;
        font-size: 1rem;
        padding: 0 4px;
    }
    &__form {
        margin: 40px 0 0;
        padding: 0;
        position: relative;
    }

    &__form-wrapper {
        border-bottom: none;
        padding-bottom: 23px;
        margin-right: 2.85%;
        margin-left: 2.85%;

        @include break(small) {
            margin-right: $side-margins;
            margin-left: $side-margins;
        }
    }

    &__footer-nav {
        padding: 30px 2.85%;
        @include break(small) {
            padding: 30px $side-margins;
        }
        @include head-line('top');
        margin-top: 10px; // based on define-groups.html
        width: 100%;

        .set-password & {
            margin-top: 20px;
        }
    }
    &__footer-links {
        @include row;
    }
    &__footer-link {
        @include xs(math.div(1, 1));
        @include sm(1 * 0.5);
        margin-bottom: 12px;

        .set-password & {
            margin: 0 auto;
        }

        @include break(small) {
            margin-bottom: 0;
        }
    }

    &__button-primary {
        background-position: center center;
        border-radius: 0;
        cursor: pointer;
        font-family: $sans-bold;
        font-size: 0.75rem;
        letter-spacing: 1.5px;
        line-height: 1rem;
        text-align: center;
        text-transform: uppercase;
        color: $white;
        border: none;

        &[disabled] {
            color: $light-gray;
            pointer-events: none;

            .fill {
                fill: $light-gray;
            }
        }

        @include break(medium) {
            padding: 22px 0;
        }

        background-color: $link-blue;
        padding: 22px 0;
        width: 100%;
        &:hover,
        &:focus {
            background-color: $link-blue-hover;
        }
        &.button-disabled {
            background-color: $light-gray;
        }
    }

    &__button-primary {
        :disabled {
            background-color: $light-gray;
        }
    }

    &__form-items {
        @include row;
    }

    &__form-item {
        @include xs(math.div(1, 1));
        @include md(1 * 0.5);
        margin-bottom: 43px;
    }
    &__form-label {
        @include global-input__label;

        &.disabled {
            color: $gray-light-light;
        }

        .form-steps__form-input {
            margin-top: 5px;
        }
    }
    &__form-input {
        @include global-input__field;

        border: 2px solid $light-gray;
        margin-top: auto;
    }

    // below input element <span class="form-steps__error"></span>
    &__error {
        color: $red;
        @include mid-demi-font;
        font-style: italic;
        margin-top: 7px;
        display: block;
    }

    &__field-requirements {
        color: $fi-black;
        margin: 0;
    }
    &__requ-list {
        li {
            &:before {
                content: '-';
                display: inline-block;
                margin: 2px 5px 2px 0;
            }
        }
    }
}
