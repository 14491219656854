@import '@pnsk/ui-common/styles/colors';
@import '@pnsk/ui-common/styles/globals';
@import '@pnsk/ui-common/styles/grid';

body.device-ios {
    #userway-override {
        bottom: toRem(120);
    }
}

#userway-override {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50% !important;
    padding: toRem(3);
    bottom: toRem(80);
    right: toRem(16);
    left: auto;
    background: $fi-dark-blue-color;
    z-index: 999;
    cursor: pointer;
}

#userway-override .userway-icon img {
    height: toRem(40);
    width: toRem(40);
}

#userway-override .userway-icon {
    display: flex;
}

@include break(medium) {
    #userway-override .userway-icon img {
        height: toRem(48);
        width: toRem(48);
    }
}
