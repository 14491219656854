@charset "UTF-8"; // sass-lint:disable-line quotes

@import './font-faces';

@import '@pnsk/ui-common/styles/colors';
@import '@pnsk/ui-common/styles/fonts';
@import '@pnsk/ui-common/styles/globals';
@import '@pnsk/ui-common/styles/grid';
@import '@pnsk/ui-common/styles/colors';
@import '@pnsk/ui-common/styles/mixins/fonts';
@import '@pnsk/ui-common/styles/mixins/hover';
@import '@pnsk/ui-common/styles/mixins/misc';
@import '@pnsk/ui-common/styles/mixins/form';
@import '@pnsk/ui-common/styles/vendors/cdk';
@import '@pnsk/ui-common/styles/fi/layout';
@import '@pnsk/ui-common/styles/fi/typographies';

@import '../../../private/src/styles/normalize';

@import './variables';
@import './common';

@import './page';
@import './aside';
@import './header';
@import './form';
@import './contact';
@import './billboard';
@import './request-access';
@import './webinar';
@import './graphics';
@import './modal';
@import './explore-tools';
@import './input-mask';
@import './ahead-road';
@import './download';
@import './user-way';

@import './reset-password';
@import './logout';
@import './footer';
@import './app-logo';

.set-password {
    .form-steps__container {
        padding-top: toRem(100);
    }
}
