body {
    height: 100%;
    margin: 0;

    @include fontMedium();
    color: $fi-dark-gray-color;
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.2rem;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;

    &:not(.focus-visible) *:focus {
        outline: none !important;
    }
}

.landing-aside-link {
    color: $fi-white-color;
    text-decoration: underline;

    &:hover,
    &:focus {
        color: $fi-white-color;
    }
}

.landing-aside-btn {
    display: inline;
    padding: 0;

    line-height: normal;
    text-decoration: underline;

    &--bordered {
        height: toRem(48);
        padding: 0 toRem(20);
        margin-top: toRem(16);
        text-decoration: none;
        border: toRem(1) solid $fi-white-color;
        border-radius: $landing-button-border-radius;
        transition: $background-color-transition;
        font-size: toRem(14);
        color: $fi-white-color;
        background: $fi-overlay-blue-color;
        @include fontHeavy();

        &:hover {
            color: $fi-light-sky-blue-color;
            border-color: $fi-sam-blue-color;
            background: $fi-overlay-blue-color;
        }

        @include break(large) {
            height: toRem(40);
        }
    }
}

.landing-checkbox {
    $base: '.landing-checkbox';
    $size: toRem(18);

    position: relative;

    display: flex;
    align-items: center;

    &__input {
        width: $size;
        height: $size;
        margin: 0;

        opacity: 0;
    }

    &__container {
        display: flex;

        position: absolute;
        top: 0;
        left: 0;

        width: $size;
        height: $size;

        border: $fi-border-button-color;
        border-radius: $common-border-radius;

        background-color: $fi-white-color;

        #{$base}__input:checked + & {
            border-color: $fi-dark-blue-color;
            background-color: $fi-dark-blue-color;
        }
    }

    &__icon {
        width: $size - toRem(4);
        height: $size - toRem(4);
        margin: auto;

        fill: $fi-white-color;
        opacity: 0;

        #{$base}__input:checked + #{$base}__container & {
            opacity: 1;
        }
    }

    &__text {
        margin-left: toRem(8);

        font-size: toRem(14);
    }
}
