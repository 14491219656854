.landing-aside {
    display: flex;
    flex-direction: column;

    overflow-x: hidden;
    overflow-y: auto;
    height: 100%;

    background-color: $landing-color-purple;
    color: $fi-white-color;

    scrollbar-width: thin;
    scrollbar-color: transparent transparent;

    &::-webkit-scrollbar {
        width: toRem(8);
        background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
        background-color: transparent;
        border-radius: toRem(8);
    }

    &:hover {
        scrollbar-color: $fi-white-25-color transparent;

        &::-webkit-scrollbar-thumb {
            background-color: $fi-white-25-color;
        }
    }
}
