//Set up global media queries
$smallest: 0rem;
$small-tablet: 37.5rem; // 600px: >= small tablet portrait
$tablet: 56.625rem; // 906px: >= big tablet portrait
$laptop: 77.5rem; // 1240: >= laptop
$desktop: 90rem; // 1440: >= xl desktop

@mixin break($name) {
    @if $name == 'small' {
        @media screen and (min-width: $smallest) {
            @content;
        }
    } @else if $name == 'medium' {
        @media screen and (min-width: $small-tablet) {
            @content;
        }
    } @else if $name == 'xmedium' {
        @media screen and (min-width: $tablet) {
            @content;
        }
    } @else if $name == 'large' {
        @media screen and (min-width: $laptop) {
            @content;
        }
    } @else if $name == 'xlarge' {
        @media screen and (min-width: $desktop) {
            @content;
        }
    }
}

//GRID
@mixin grid {
    display: flex;
    flex-flow: row wrap;
}

@mixin margins($value: -4) {
    margin-left: toRem($value);
    margin-right: toRem($value);
}

@mixin paddings($value: 4) {
    padding-left: toRem($value);
    padding-right: toRem($value);
}

@mixin row($name: small) {
    display: flex;
    flex: 0 1 100%;
    flex-flow: row wrap;
    @include margins;

    @include break($name) {
        @include margins(-10);
    }
}

@mixin col {
    @include paddings;

    @include break(medium) {
        @include paddings(10);
    }
}

@mixin xs($span, $noDisplayProperty: false) {
    @include paddings;

    @include break(medium) {
        @include paddings(10);
    }

    @if ($noDisplayProperty == false) {
        display: block;
    }

    width: $span * 100%;
    // flex-basis:  $span * 100%;
    //IE11 ignores border-box property, breaking flex-basis;
    flex-basis: auto;
}
@mixin sm($span, $noDisplayProperty: false) {
    @include paddings;

    @include break(medium) {
        @include paddings(10);

        @if ($noDisplayProperty == false) {
            display: block;
        }

        width: $span * 100%;
        // flex-basis:  $span * 100%;
        //IE11 ignores border-box property, breaking flex-basis;
        flex-basis: auto;
    }
}
@mixin md($span, $noDisplayProperty: false) {
    @include paddings;

    @include break(medium) {
        @include paddings(10);
    }
    @include break(xmedium) {
        @if ($noDisplayProperty == false) {
            display: block;
        }

        width: $span * 100%;
        // flex-basis:  $span * 100%;
        //IE11 ignores border-box property, breaking flex-basis;
        flex-basis: auto;
    }
}
@mixin lg($span, $noDisplayProperty: false) {
    @include paddings;

    @include break(medium) {
        @include paddings(10);
    }
    @include break(large) {
        @if ($noDisplayProperty == false) {
            display: block;
        }

        width: $span * 100%;
        // flex-basis:  $span * 100%;
        //IE11 ignores border-box property, breaking flex-basis;
        flex-basis: auto;
    }
}
@mixin xl($span, $noDisplayProperty: false) {
    @include paddings;

    @include break(medium) {
        @include paddings(10);
    }
    @include break(xlarge) {
        @if ($noDisplayProperty == false) {
            display: block;
        }

        display: block;
        width: $span * 100%;
        // flex-basis:  $span * 100%;
        //IE11 ignores border-box property, breaking flex-basis;
        flex-basis: auto;
    }
}
