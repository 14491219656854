@font-face {
    font-family: 'Avenir LT W01_45 Book1475508';

    src: url('../assets/fonts/avenir-lt-w01-45-book.woff2') format('woff2'),
        url('../assets/fonts/avenir-lt-w01-45-book.woff') format('woff');
}

@font-face {
    font-family: 'Avenir LT W01_65 Medium';

    src: url('../assets/fonts/avenir-lt-w01-65-medium.woff') format('woff');
}

@font-face {
    font-family: 'Avenir LT W01_85 Heavy1475544';

    src: url('../assets/fonts/avenir-lt-w01-85-heavy.woff2') format('woff2'),
        url('../assets/fonts/avenir-lt-w01-85-heavy.woff') format('woff');
}

@font-face {
    font-family: 'Avenir LT W01_95 Black1475556';

    src: url('../assets/fonts/avenir_lt_w01_95_black.woff2') format('woff2'),
        url('../assets/fonts/avenir_lt_w01_95_black.woff') format('woff');
}
