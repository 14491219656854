
@mixin responsive($display) {
    &--desktop {
        display: none;
    }

    @include break(medium) {
        &--desktop {
            display: $display;
        }
        &--mobile {
            display: none;
        }
    }
}

.app-wrap {
    &__header {
        background: $fi-dark-blue-color;
        min-height: 65px;
    }

    &__footer {
        color: #89898f;
        background: $fi-white-color;
        border-top: 1px solid $fi-border-module-color;
        min-height: 70px;
        padding: 18px 0;
    }
}

.container {
    &__inner {
        @include side-padding(0);
    }

    &--nested & {
        &__inner {
            padding: 0 0;
        }
    }

    &__justify-center {
        display: flex;
        justify-content: center;
    }
    &__no-offset {
        padding: 0;
        margin: 0;
    }
}

.copyrights {
    display: flex;
    align-items: center;

    &__logo {
        margin-right: 18px;
    }
}

.page {
    &__header,
    &__nav,
    &__main,
    &__flash-messages {
        background: $fi-white-color;
    }
    &__header {
        padding: toRem(20);
        @include break(medium) {
            padding: toRem(25) 0 0;
        }
    }
    &__notifications {
        margin-bottom: 0;

        @include break(medium) {
            margin-bottom: toRem(20);
        }
    }
    &__main {
        margin-bottom: 40px;
        border: 1px solid $fi-border-module-color;
        border-radius: $common-border-radius;
    }
}

/*
 * @deprecated This class may be removed in future versions
 */
.slot {
    display: flex;
    @include responsive(flex);

    justify-content: space-between;
    margin: 0 toRem(-5);

    $base: &;

    &__inner {
        @include responsive(block);

        padding: 0 toRem(5);

        &--col-1 {
            flex-basis: calc(100% / 6);
        }
        &--spanned {
            flex-grow: 1;
        }
        &--spanned-filters {
            display: flex;
            flex-grow: 1;
            flex-shrink: 4;
        }
        &--separator {
            &::before {
                content: '';
                background: $fi-border-button-color;
                display: block;
                height: 100%;
                width: toRem(1);
                margin: 0 toRem(5);
            }
        }
    }

    // wraps regular sized button
    &--force-regular-height {
        height: toRem(40);
    }

    &--wrapped {
        flex-wrap: wrap;
        margin: toRem(-5);

        #{$base + '__inner'} {
            padding: toRem(5);
        }
    }

    &--align-left {
        justify-content: flex-start;
    }
    &--center-vertically {
        align-items: center;
    }
    &--align-bottom {
        align-items: flex-end;
    }
    &--align-right {
        justify-content: flex-end;
    }

    &--tablet-centered {
        justify-content: center;

        @include break(medium) {
            justify-content: space-between;
        }
    }

    &--mobile-column {
        flex-direction: column;
        align-items: flex-start;

        #{$base + '__inner'} {
            width: 100%;
        }

        @include break(xmedium) {
            flex-direction: row;
            align-items: center;

            #{$base + '__inner'} {
                width: auto;
                padding: 0;
            }
        }
    }
}

.two-columns {
    &__row {
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;

        @include break(large) {
            flex-wrap: nowrap;
        }
    }

    &__cell {
        flex: 1 0 100%;
        max-width: 100%;

        &:last-child {
            margin-bottom: 0;
        }

        @include break(large) {
            $marginCell: toRem(12);

            flex: 1 0 50%;
            max-width: calc(50% - #{$marginCell});
            margin-right: $marginCell;
            margin-bottom: 0;

            & + & {
                margin-left: toRem(12);
                margin-right: 0;
            }
        }

        &--align-end {
            align-self: flex-end;
        }
    }
}
