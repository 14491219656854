@import '@pnsk/ui-common/styles/colors';
@import '@pnsk/ui-common/styles/mixins/fonts';

html {
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    height: 100%;
    scroll-behavior: smooth;
}
*,
*:after, // sass-lint:disable-line force-pseudo-nesting
*:before {
    // sass-lint:disable-line force-pseudo-nesting
    box-sizing: border-box;
}
h1,
h2,
h3,
h4,
p,
blockquote,
figure,
ol,
ul {
    margin: 0;
    padding: 0;
}
main,
li {
    display: block;
}
h1,
h2,
h3,
h4,
h5 {
    font-size: inherit;
    font-weight: normal;
}
h1 {
    font-size: 1.6rem;
    line-height: 1.92rem;
    margin-bottom: 33px;

    @include break(small) {
        font-size: 2rem;
        line-height: 2.4rem;
    }
}
h2 {
    font-size: 1.25rem;
    line-height: 1.5rem;
}
h3 {
    font-size: 1.125rem;
}
h4 {
    font-size: 0.75rem;
    line-height: 1rem;
}
p {
    color: $fi-black;
    line-height: 1.46rem;
    margin: 0;
}
strong {
    font-weight: bold;
}
a,
button {
    color: inherit;
}
a {
    color: $link-blue;
    text-decoration: none;
    &:hover,
    &:focus {
        color: $link-blue-hover;
    }
    &[ng-click] {
        cursor: pointer;
    }
}

// TODO - turn back on.
// ::selection {
//     background-color: transparent;
// }

button {
    background: none;
    border: 0;
    cursor: pointer;
    font: inherit;
    letter-spacing: inherit;
    overflow: visible;

    -webkit-font-smoothing: antialiased;

    // remove the margin in Firefox and Safari
    margin: 0;
}
::-moz-focus-inner {
    border: 0;
    padding: 0;
}
img {
    border: 0;
    height: auto;
    max-width: 100%;
}

fieldset {
    border: none;
    margin: 0;
    padding: 0;
}

textarea {
    resize: vertical;
}

input {
    &[type='search'] {
        box-sizing: content-box;

        appearance: textfield; // 1
    }
    &[type='button'] {
        border: none;
        border-radius: 0;
    }
    &::-ms-clear {
        display: none;
    }

    &[type='text'] {
        font-size: 16px;
        -webkit-appearance: none;
    }

    &[type='date'],
    &[type='number'] {
        &::-webkit-inner-spin-button,
        &::-webkit-calendar-picker-indicator {
            -webkit-appearance: none;
        }
    }

    &:focus {
        @include placeholder {
            color: transparent;
        }
    }
    &:disabled {
        background-color: $fi-ext7-color;
    }
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}
td {
    border-spacing: 0;
    padding: 0;
}
th {
    font-weight: inherit;
}
