@import '../typographies';

@mixin textColor($color) {
    color: $color;
    fill: $color;
}

@mixin link() {
    a {
        color: inherit;
    }

    &:hover {
        @include textColor($fi-bright-blue-color);
    }

    cursor: pointer;
    transition-property: color;
    transition-duration: 0.3s;
    transition-timing-function: linear;
}

@mixin linkWithIcon() {
    &:hover {
        svg {
            fill: $fi-bright-blue-color;
        }
    }

    svg {
        transition: fill 0.3s linear;
    }
}

.fi-heading {
    color: $fi-gray-color;
    @include fontMedium();
    letter-spacing: normal;
    &--black {
        color: $fi-chinese-black-color;
    }
    &--dark {
        color: $fi-dark-gray-color;
    }
    &--light {
        color: $fi-light-gray-color;
    }
    &--lighter {
        color: $fi-white-color;
    }
    &--light-transparent {
        color: $fi-white-st-color;
    }
    &--semi-transparent {
        opacity: 0.5;
    }
    &--blue {
        color: $fi-dark-blue-color;
        a {
            color: inherit;
        }
    }
    &--primary {
        @include heading-primary();
    }
    &--secondary {
        @include heading-secondary();
    }
    &--mobile {
        font-size: toRem(20);
        line-height: toRem(12);
    }
    &--tertiary {
        font-size: toRem(18);
        line-height: toRem(22);
        @include fontHeavy;

        @include break(medium) {
            @include fontMedium;
        }
    }
    &--tertiary-lighter {
        @include heading-tertiary-lighter();
    }
    &--tertiary-heavy {
        @include heading-tertiary-heavy();
    }
    &--margin-less {
        margin: 0;
    }
    &--quaternary {
        @include heading-quaternary();
    }
    &--quaternary-heavy {
        font-size: toRem(16);
        line-height: toRem(20);
        @include fontHeavy;
    }
    &--quinary {
        font-size: toRem(14);
        line-height: toRem(20);
    }
    &--responsive-primary {
        font-size: toRem(22);
        line-height: toRem(26);
        @include fontHeavy;

        @include break(medium) {
            font-size: toRem(26);
            line-height: toRem(30);
            @include fontMedium;
        }
    }
    &--responsive-secondary {
        font-size: toRem(18);
        line-height: toRem(22);
        @include fontHeavy;

        @include break(medium) {
            font-size: toRem(22);
            line-height: toRem(26);
            @include fontMedium;
        }
    }
    &--responsive-medium {
        font-size: toRem(20);
        line-height: toRem(22);
        @include fontMedium;

        @include break(medium) {
            font-size: toRem(22);
            line-height: toRem(26);
        }
    }
    &--responsive-heavy {
        font-size: toRem(22);
        line-height: toRem(26);
        @include fontHeavy;

        @include break(medium) {
            font-size: toRem(30);
            line-height: toRem(32);
        }
    }

    &--responsive-table-header {
        @include heading-tertiary-lighter();

        @include break(medium) {
            @include heading-secondary();
        }
    }

    &--responsive-grid-header {
        @include heading-tertiary-lighter();

        @include break(xmedium) {
            @include heading-secondary();
        }
    }

    &--responsive-grid-heavy-header {
        @include heading-tertiary-heavy();

        @include break(medium) {
            font-size: toRem(16);
            line-height: toRem(20);
            @include fontHeavy;
        }
    }

    // todo: can we try to use heading class only for the size/color, and use specific wrappers so as to align the heading with the layout?
    &--header {
        margin: 0 toRem(20) toRem(20) toRem(20);
        @include break(medium) {
            margin: 0 0 toRem(12) 0;
        }
    }
    &--heavy {
        @include fontBlack();
    }
    &--heavy-avenir {
        @include fontHeavy();
    }
    &--heavy-small {
        @include fontBlack();
        font-size: toRem(12);
    }
    &--extra-spacing {
        letter-spacing: toRem(2);
    }
    &--link {
        @include link();
    }
    &--link-with-icon {
        @include link();
        @include linkWithIcon();
    }
}

.fi-text {
    @include textColor($fi-gray-color);

    @media print {
        @include textColor($print-color);
    }

    &--dark {
        @include textColor($fi-dark-gray-color);
    }
    &--light {
        @include textColor($fi-light-gray-color);
    }
    &--lighter {
        @include textColor($fi-border-button-color);
    }
    &--white {
        @include textColor($fi-white-color);
    }
    &--white-st {
        @include textColor($fi-white-st-color);
    }
    &--dark-blue {
        @include textColor($fi-dark-blue-color);
    }
    &--blue {
        @include textColor($fi-bright-blue-color);
    }
    &--error {
        @include textColor($fi-error-color);
    }
    &--label {
        @include textColor($fi-middle-gray-color);
    }
    &--light-gray {
        @include textColor($fi-cape-cod-gray-color);
    }
    &--lighter-gray {
        @include textColor($fi-oslo-gray-color);
    }
    &--dark-gray {
        @include textColor($fi-text-description-color);
    }
    &--locked {
        @include textColor($fi-locked-color);
    }
    &--settings-color {
        @include textColor($fi-settings-gray-color);
    }
    &--black {
        @include textColor($fi-chinese-black-color);
    }

    &--link {
        @include textColor($fi-dark-blue-color);
        @include link();
    }

    &--link-with-icon {
        @include textColor($fi-dark-blue-color);
        @include link();
        @include linkWithIcon();
    }

    &--settings-dark-gray {
        @include textColor($fi-settings-dark-gray-color);
    }

    &--green {
        @include textColor($fi-success-color);
    }

    @include fontBook();

    &--medium {
        @include fontMedium();
    }
    &--heavy {
        @include fontHeavy();
    }
    &--roman {
        @include fontRoman();
    }

    &--print {
        @include textColor($print-color);
    }

    @include text-regular();

    &--xx-small {
        font-size: toRem(10);
        line-height: toRem(12);
    }
    &--x-small {
        @include text-x-small();
    }
    &--small {
        @include text-small();

        &-mobile {
            font-size: toRem(14);
            line-height: toRem(18);

            @include break(medium) {
                font-size: initial;
                line-height: initial;
            }
        }

        &-tablet {
            font-size: toRem(14);
            line-height: toRem(18);

            @include break(xmedium) {
                font-size: initial;
                line-height: initial;
            }
        }
    }
    &--large {
        font-size: toRem(18);
        line-height: toRem(22);
    }
    &--x-large {
        font-size: toRem(22);
        line-height: 1.36;
    }
    &--xx-large {
        font-size: toRem(26);
        line-height: 1.36;
    }

    &--responsive-medium {
        font-size: toRem(16);
        line-height: toRem(20);

        @include break(medium) {
            font-size: toRem(14);
            line-height: toRem(18);
        }
    }
    &--responsive-family {
        @include fontBook();

        @include break(medium) {
            @include fontMedium();
        }
    }
    &--responsive-book {
        font-size: toRem(16);
        line-height: toRem(24);

        @include break(medium) {
            font-size: toRem(16);
        }
    }
    &--inherit-size {
        font-size: inherit;
    }

    &--underline {
        text-decoration: underline;
    }

    &--lowercase-capitalize {
        display: inline-block;
        text-transform: lowercase;

        &::first-letter {
            text-transform: uppercase;
        }
    }

    &--no-wrap {
        white-space: nowrap;
    }

    &--italic {
        font-style: italic;
    }

    &--uppercase {
        text-transform: uppercase;
    }

    &--no-cursor {
        cursor: auto;
    }
}

.fi-list {
    &__item {
        margin: toRem(10) 0;
        &--vertical-margin-20 {
            margin: toRem(20) 0;
        }
    }
    &--inline & {
        &__item {
            display: inline;
            margin: 0 toRem(5);
            &:first-child {
                margin-left: 0;
            }
            &:last-child {
                margin-right: 0;
            }
        }
    }
}

@mixin textBookDefault() {
    font-family: $fi-font-book;
    font-size: toRem(14);
    line-height: toRem(18);
}
