@use '../globals';
$cdk-z-index: globals.$cdk-z-index;

$base-overlay-class: 'fi-overlay-pane';
$hidden-under-header-overlay: 'hidden-under-header-overlay';

.cdk-global-overlay-wrapper,
.cdk-overlay-container {
    pointer-events: none;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

.cdk-overlay-container {
    position: fixed;
    z-index: $cdk-z-index;
}

.cdk-overlay-container:empty {
    display: none;
}

.cdk-global-overlay-wrapper {
    display: flex;
    position: absolute;
    z-index: $cdk-z-index;
}

.cdk-overlay-pane {
    position: absolute;
    pointer-events: auto;
    box-sizing: border-box;
    z-index: $cdk-z-index;
    display: flex;
    max-width: 100%;
    max-height: 100%;
}

.cdk-overlay-backdrop {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: $cdk-z-index;
    pointer-events: auto;
    -webkit-tap-highlight-color: transparent;
    transition: opacity 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
    opacity: 0;
}

.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
    opacity: 1;
}

@media screen and (-ms-high-contrast: active) {
    .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
        opacity: 0.6;
    }
}

.cdk-overlay-transparent-backdrop,
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
    opacity: 0;
}

.cdk-overlay-connected-position-bounding-box {
    position: absolute;
    z-index: $cdk-z-index;
    display: flex;
    flex-direction: column;
    min-width: 1px;
    min-height: 1px;
}

.cdk-global-scrollblock {
    position: fixed;
    width: 100%;
    overflow-y: scroll;
}

// Custom styles for modal backdrop animation
// TODO: find better place for this
.cdk-overlay-backdrop {
    transition: opacity 300ms ease;
    opacity: 0;
    will-change: opacity;
}

.cdk-overlay-dark-backdrop {
    background: rgba($fi-modal-overlay-color, 0.8);
}

.cdk-overlay-container:has(.#{$base-overlay-class}--#{$hidden-under-header-overlay}),
.cdk-overlay-connected-position-bounding-box:has(.#{$base-overlay-class}--#{$hidden-under-header-overlay}),
.cdk-overlay-pane.#{$base-overlay-class}--#{$hidden-under-header-overlay} {
    z-index: $page-sticky-header-z-index;
}
