.landing-explore-tools {
    $base: &;

    &__title {
        width: 100%;
        max-width: toRem(350);
        padding: toRem(50) toRem(20);
        margin: 0 auto;

        @include fontMedium();
        font-size: toRem(26);
        text-align: left;
        line-height: toRem(30);
        color: $fi-dark-gray-color;

        @include break(medium) {
            text-align: center;
            max-width: toRem(490);
        }

        @include break(large) {
            max-width: 100%;
        }
    }

    &__list {
        width: 100%;
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        padding: 0 toRem(20) toRem(32);

        @include break(medium) {
            padding-bottom: toRem(56);
        }

        @include break(large) {
            flex-flow: row nowrap;
            justify-content: center;
            padding: 0 0 toRem(97);
        }
    }

    &__list-item {
        display: flex;
        flex-flow: column nowrap;
        align-items: flex-start;

        @include break(medium) {
            align-items: center;
        }

        @include break(large) {
            flex: 0 1 toRem(334);
            margin: 0 toRem(12) 0;
        }

        & + #{$base }__list-item {
            padding-top: toRem(56);

            @include break(large) {
                padding-top: 0;
            }
        }
    }

    &__image {
        display: block;
        position: relative;
        width: toRem(334);
        height: toRem(190);

        @include break(large) {
            width: 100%;
        }

        &--technology-consulting-services {
            @include backgroundImg('../assets/images/penske-technology.png');
        }

        &--penske-driver-app {
            @include backgroundImg('../assets/images/penske-driver-app.jpg');
        }

        &--fleet-insight-webinars {
            @include backgroundImg('../assets/images/penske-webinars.png');
        }
    }

    &__app-name {
        display: none;
        padding: toRem(34) 0 toRem(28);
        white-space: nowrap;

        @include fontMedium();
        font-size: toRem(20);

        @include break(large) {
            display: block;
        }
    }

    &__link {
        &-title {
            display: block;
            cursor: pointer;
            @include fontHeavy();

            &--mobile {
                color: $fi-dark-blue-color;
                padding: toRem(16) 0;

                font-size: toRem(20);

                @include break(large) {
                    display: none;
                }
            }

            &--desktop {
                display: none;
                padding: toRem(13) toRem(41);
                border: toRem(1) solid $fi-border-button-color;
                border-radius: toRem(3);

                color: $fi-gray-color;
                font-size: toRem(14);

                @include break(large) {
                    display: block;
                }

                &:hover {
                    border-color: $fi-dark-blue-color;
                    color: $fi-dark-blue-color;
                }
            }
        }
    }
}
